import { css, cx } from "@emotion/css";
import type { ButtonBaseActions } from "@material-ui/core";
import CheckboxField from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { FormFieldProps } from "../FormFieldProps";
import { FormFieldTitle } from "../FormFieldTitle";
export interface CheckboxProps extends FormFieldProps<boolean> {
    /**
     * The title to display above the checkbox.
     */
    title?: string;
    /**
     * Controls whether or not to include the top and bottom margins around the checkbox.
     */
    noMargin?: boolean;
    /**
     * Controls whether the checkbox should be automatically focused.
     */
    autoFocus?: boolean;
    /**
     * The note to display under the checkbox.
     */
    note?: string | JSX.Element;
    /**
     * Controls whether the checkbox is disabled.
     */
    disabled?: boolean;
    /**
     * The label to display alongside the checkbox.
     */
    label?: string | JSX.Element;
    /**
     * The classname to apply to the checkbox.
     *
     * @deprecated Please avoid using this. This property will be removed in the near future.
     */
    className?: string;
    /**
     * The error to display under the checkbox.
     */
    error?: string;
    /**
     * The warning to display under the checkbox.
     *
     * Will not be shown if an error is specified.
     */
    warning?: string;
    /**
     * The accessible name of the checkbox.
     */
    accessibleName?: string;
}
export const Checkbox = ({ title, label, className, value, noMargin, onChange, note, error, warning, accessibleName, autoFocus, disabled }: CheckboxProps) => {
    const checkBoxRef = React.useRef<ButtonBaseActions>(null);
    const handleChange = React.useCallback((event: unknown, isChecked: boolean) => {
        if (onChange) {
            onChange(isChecked);
        }
    }, [onChange]);
    const isMessageProvided = Boolean(error) || Boolean(warning);
    const message = isMessageProvided && (error || warning);
    const messageColorStyles = isMessageProvided ? (message === error ? errorMessageStyles : warningMessageStyles) : undefined;
    return (<div className={cx(className, { [containerMarginStyles]: !noMargin })}>
            {title && <FormFieldTitle title={title}/>}
            <FormControlLabel control={<CheckboxField className={checkboxStyles} action={checkBoxRef} checked={value} aria-label={accessibleName} onChange={handleChange} autoFocus={autoFocus} disableRipple disableTouchRipple disableFocusRipple/>} label={label} className={formControlLabelStyles} disabled={disabled}/>
            {message && <div className={cx(messageStyles, messageColorStyles)}>{message}</div>}
            {note && <CheckboxNote note={note}/>}
        </div>);
};
const formControlLabelClasses = {
    root: "MuiFormControlLabel-root",
    label: "MuiFormControlLabel-label",
};
const checkboxClasses = {
    root: "MuiCheckbox-root",
};
const commonClasses = {
    checked: "Mui-checked",
    disabled: "Mui-disabled",
    focusVisible: "Mui-focusVisible",
};
const containerMarginStyles = css({
    marginTop: "1rem",
    marginBottom: "1rem",
    width: "100%",
});
const formControlLabelStyles = css({
    [`&.${formControlLabelClasses.root}`]: {
        width: "100%",
        margin: 0,
        [`.${formControlLabelClasses.label}`]: {
            fontSize: "inherit",
            color: themeTokens.color.text.primary,
        },
        [`.${formControlLabelClasses.label}.${commonClasses.disabled}`]: {
            color: themeTokens.color.text.disabled,
            cursor: "not-allowed",
        },
    },
});
const checkboxStyles = css({
    [`&.${checkboxClasses.root}`]: {
        padding: 0,
        marginRight: space[8],
        borderRadius: borderRadius.extraSmall,
        color: themeTokens.color.icon.primary,
        ":hover": {
            backgroundColor: "unset",
        },
    },
    [`&.${checkboxClasses.root}.${commonClasses.checked}`]: {
        color: themeTokens.color.icon.selected,
        ":hover": {
            backgroundColor: "unset",
        },
    },
    [`&.${checkboxClasses.root}.${commonClasses.disabled}`]: {
        color: themeTokens.color.icon.disabled,
    },
    [`&.${checkboxClasses.root}.${commonClasses.focusVisible}`]: {
        boxShadow: themeTokens.shadow.focused,
    },
});
const messageStyles = css({
    font: text.regular.default.xSmall,
});
const warningMessageStyles = css({
    color: themeTokens.color.text.warning,
});
const errorMessageStyles = css({
    color: themeTokens.color.text.danger,
});
function CheckboxNote({ note }: {
    note: string | JSX.Element;
}) {
    return <div className={checkboxNoteStyles}>{note}</div>;
}
const checkboxNoteStyles = css({
    marginLeft: "2rem",
    font: text.regular.default.xSmall,
    marginBottom: "0.5rem",
    color: themeTokens.color.text.tertiary,
});
