import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import React from "react";
import { useIsLargerThanIpadResolution } from "../../hooks";
import type { DesignSystemLinkHref } from "../../routing";
import { useSetPageTitlePrimarySegmentEffect } from "../../routing";
import type { BreadcrumbItem } from "../Breadcrumbs";
import { Breadcrumbs } from "../Breadcrumbs";
import type { SimpleMenuItem } from "../MenuItems";
import type { PageAction, PrimaryPageAction } from "../PageActions";
import { PageActions } from "../PageActions";
import { LeadingSegment } from "./LeadingSegment";
export interface PageHeaderPrimaryProps {
    /**
     * The breadcrumbs showing at the top of primary header.
     *
     * The breadcrumbs is only visible when this property is defined.
     */
    breadcrumbs?: BreadcrumbItem[];
    /**
     * The logo to show to the left of the header title.
     *
     * The logo is only visible when this property is defined.
     */
    logo?: PageHeaderPrimaryLogo;
    /** The title of the PageHeaderPrimaryProps. */
    title: string;
    /** clicking the logo will take you to this URL */
    logoLinkURL?: DesignSystemLinkHref;
    /**
     * The icon to show next to the header title.
     *
     * The icon next to the title is only visible when this property is defined.
     */
    titleIcon?: ReactElement;
    /** A list of chips that showing next to the header title with a divider at the beginning */
    titleChips?: ReactElement[];
    /**
     * An optional primary action to display on the right side of the header
     */
    primaryAction?: PrimaryPageAction;
    /**
     * An optional list of actions to display on the right side of the header
     */
    actions?: PageAction[];
    /**
     * An optional list of overflow items that forms an overflow menu on the right side of the header
     */
    overflowActions?: SimpleMenuItem[];
    statusSection?: React.ReactNode;
    /**
     * Temporary prop to remove padding from the header while Front end foundations updates Page Layouts
     */
    pageLayoutV2?: boolean;
}
export interface PageHeaderPrimaryLogo {
    /** The url of the logo */
    href: string;
    /** The accessible name of the logo */
    accessibleName: string;
}
export function PageHeaderPrimary({ breadcrumbs, statusSection, logo, logoLinkURL, title, titleIcon, titleChips, primaryAction, actions, overflowActions, pageLayoutV2 }: PageHeaderPrimaryProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    useSetPageTitlePrimarySegmentEffect(title);
    return (<header className={cx(primaryHeaderStyles, { [headerPrimaryMobileStyles]: !isLargerThanIpad, [headerPrimaryNoPaddingStyles]: pageLayoutV2 })}>
            <div className={breadcrumbContainerStyles}>{breadcrumbs && <Breadcrumbs items={breadcrumbs} showBackIcon={true}/>}</div>
            <div className={cx(headerContentStyles, { [headerContentMobileStyles]: !isLargerThanIpad })}>
                <LeadingSegment logoLinkURL={logoLinkURL} logo={logo} title={title} titleIcon={titleIcon} titleChips={titleChips}/>
                <PageActions primaryAction={primaryAction} actions={actions} overflowActions={overflowActions}/>
            </div>
            {statusSection}
        </header>);
}
const primaryHeaderStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: `${space["16"]} ${space["32"]}`,
    gap: space["12"],
});
const headerPrimaryMobileStyles = css({
    padding: `${space["24"]} 0 ${space["12"]}`,
});
const headerPrimaryNoPaddingStyles = css({
    padding: "0",
    paddingBottom: space["16"],
});
const breadcrumbContainerStyles = css({
    display: "flex",
    alignItems: "flex-end",
    height: "2rem",
});
const headerContentStyles = css({
    display: "flex",
    justifyContent: "space-between",
    gap: space["48"],
    // This header content should have a min height set to the height of the buttons.
    // This prevents the header from changing size to accommodate buttons that are added or removed.
    minHeight: "2.25rem",
});
const headerContentMobileStyles = css({
    flexDirection: "column",
    gap: space["24"],
    alignItems: "unset",
});
