import { css } from "@emotion/css";
import { themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { NoSearchResultsIllustration } from "../Illustrations/NoSearchResultsIllustration";
export function NoSearchResults() {
    return (<div className={noSearchResultsStyles.root}>
            <NoSearchResultsIllustration />
            <h2 className={noSearchResultsStyles.heading}>No results found.</h2>
            <p className={noSearchResultsStyles.description}>Try adjusting your filters or search term.</p>
        </div>);
}
const noSearchResultsStyles = {
    root: css({
        padding: space["48"],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }),
    heading: css({
        color: themeTokens.color.text.primary,
        font: text.heading.small,
        margin: 0,
        marginTop: space[16],
    }),
    description: css({
        color: themeTokens.color.text.secondary,
        font: text.regular.default.medium,
        margin: 0,
        marginTop: space[8],
    }),
};
