import { css } from "@emotion/css";
import React from "react";
export function NoSearchResultsIllustration() {
    return (<svg className={illustrationStyles} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 120">
            <path fill="#F2F8FD" d="M53.168.987C72.05-4.877 88.09 16.633 100.277 32.582c12.046 15.764 23.901 34.789 18.289 53.99-5.373 18.38-25.443 26.335-43.348 31.739-14.008 4.228-27.86-.093-41.184-6.225C20.009 105.632.577 99.491.01 83.699c-.56-15.562 23.58-16.9 31.929-29.89C42.686 37.086 34.511 6.781 53.169.986Z"/>
            <path fill="#1A77CA" d="M40.794 25.736a17.469 17.469 0 0 0-18.968-2.358 17.52 17.52 0 0 0-7.343 6.76 17.575 17.575 0 0 0 .733 19.142 17.511 17.511 0 0 0 7.838 6.175 17.468 17.468 0 0 0 9.93.885 17.492 17.492 0 0 0 8.802-4.693l21.472 18.159a1.61 1.61 0 0 0 2.27-.194 1.62 1.62 0 0 0-.187-2.275l-.002-.002-21.472-18.16a17.576 17.576 0 0 0-3.073-23.439ZM39.41 47.427a13.112 13.112 0 0 1-6.388 4.153c-2.493.72-5.142.684-7.614-.105a13.114 13.114 0 0 1-6.272-4.326 13.162 13.162 0 0 1-1.23-14.553 13.129 13.129 0 0 1 5.457-5.322 13.09 13.09 0 0 1 14.481 1.63 13.165 13.165 0 0 1 1.566 18.523Z"/>
            <path fill="#FFB8B8" d="M67.904 104.793H66.1l-.858-7.986h2.662v7.986Z"/>
            <path fill="#449BE1" d="M68.572 106.79h-6.656v-.084c0-.684.274-1.34.76-1.823a2.594 2.594 0 0 1 1.831-.755h4.065v2.662Z"/>
            <path fill="#FFB8B8" d="M89.87 104.793h-2.255l-1.073-7.986h3.328v7.986Z"/>
            <path fill="#449BE1" d="M90.535 106.79h-6.656v-.084c0-.684.273-1.34.76-1.823a2.594 2.594 0 0 1 1.83-.755H90.536v2.662ZM91.2 101.788l-5.459.841-7.94-27.341-8.434 27.509-6.12-.841 7.939-31.972V67.46l1.075-3.787-.112-1.024-.783-7.167-.339-3.108-.022-.201a.692.692 0 0 1 .123-.475.67.67 0 0 1 .405-.268l2.547-.545h4.164l2.456.688.169.047.076.022-.038.323-.576 4.91-.031.27-.556 4.733-.123 1.038c4.797 3.534 4.963 7.068 4.963 7.068l6.616 31.804Z"/>
            <path fill="#FFB8B8" d="M75.56 48.888a4.326 4.326 0 1 0 0-8.652 4.326 4.326 0 0 0 0 8.652ZM60.643 63.5c.078.051.152.11.221.174l8.094-2.126.553-2.012 3.055.462-.641 3.66a1.4 1.4 0 0 1-.41.77 1.33 1.33 0 0 1-.775.363l-9.45 1.026a1.802 1.802 0 0 1-.715.797 1.726 1.726 0 0 1-2.003-.17 1.827 1.827 0 0 1-.579-.906c-.1-.355-.09-.733.024-1.084.114-.35.33-.657.617-.878a1.729 1.729 0 0 1 2.008-.077Z"/>
            <path fill="#000" d="M72.648 40.985c-.76-.097-1.594-.028-2.187.478-.592.506-.783 1.559-.222 2.103.496.481 1.273.368 1.938.505a2.61 2.61 0 0 1 1.458.94 2.81 2.81 0 0 1 .591 1.677c-.01 1.757-1.626 3.087-1.916 4.817-.198 1.181.28 2.424 1.105 3.258.825.835 1.96 1.286 3.103 1.408 1.143.122 2.299-.065 3.41-.371 1.115-.308 2.213-.744 3.15-1.448.935-.704 1.703-1.697 2.003-2.86.3-1.162.085-2.494-.69-3.387-.886-1.022-2.362-1.373-3.129-2.496-.86-1.258-.541-3.05-1.237-4.414-.465-.911-1.345-1.532-2.283-1.86-.938-.327-1.939-.392-2.927-.423-.52-.017-1.055-.023-1.54.175-.484.198-.909.648-.934 1.19-.025.542.307.708.307.708Z"/>
        </svg>);
}
const illustrationStyles = css({
    width: 120,
    height: 120,
});
