import React from "react";
import type { DesignSystemLinkHref } from "../../routing";
import { useOctopusLinkComponent } from "../../routing";
import { ListItem } from "./ListItem";
interface ListLinkItemProps {
    children: React.ReactNode;
    href: DesignSystemLinkHref;
}
export function ListItemLink({ children, href }: ListLinkItemProps) {
    const Link = useOctopusLinkComponent();
    return (<ListItem>
            <Link href={href}>{children}</Link>
        </ListItem>);
}
