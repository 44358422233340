import { css, cx } from "@emotion/css";
import { themeTokens, colorScales, space } from "@octopusdeploy/design-system-tokens";
import type { MouseEventHandler } from "react";
import React, { useRef } from "react";
import { useOnIsHoveredChanged } from "../../hooks";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/OctopusRoutingContext";
import { useIsUrlActive, useOctopusLinkComponent } from "../../routing/OctopusRoutingContext";
import { resetStyles } from "../../utils/resetStyles";
import { Tooltip } from "../Tooltip/Tooltip";
import { sideNavBarTabStyles } from "./sideNavBarTabStyles";
interface SharedSideNavBarLinkTabProps {
    /**
     * The icon for this link.
     */
    icon: React.ReactNode;
    /**
     * The link location.
     */
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    /**
     * The onClick handler associated with the tab.
     */
    onClick?: MouseEventHandler<Element>;
    /**
     * The hover handler associated with the tab.
     */
    onIsHoveredChanged?: (isHovered: boolean, hoveredElement: HTMLElement) => void;
}
interface SideNavBarLinkTabIconOnlyProps extends SharedSideNavBarLinkTabProps {
    /**
     * The required accessible name for this icon link.
     */
    accessibleName: string;
}
interface SideNavBarLinkTabWithLabelProps extends SharedSideNavBarLinkTabProps {
    /**
     * An optional accessible name for this icon link. If no accessible name is provided the label will be used.
     */
    accessibleName?: string;
    /**
     * The label for this icon link.
     */
    label: string;
}
type SideNavBarLinkTabIconLinkProps = SideNavBarLinkTabIconOnlyProps | SideNavBarLinkTabWithLabelProps;
export function SideNavBarLinkTab(props: SideNavBarLinkTabIconLinkProps) {
    if (isSideNavBarLinkTabWithLabel(props)) {
        return <SideNavBarLinkTabWithLabel {...props}/>;
    }
    const tab = <SideNavBarLinkTabIconOnly icon={props.icon} href={props.href} accessibleName={props.accessibleName} showLinkAsActive={props.showLinkAsActive} onClick={props.onClick}/>;
    // There's no label. Show a tooltip based on the accessibility label if possible.
    if (props.accessibleName) {
        return (<Tooltip content={props.accessibleName} style={{ width: "100%" }}>
                {tab}
            </Tooltip>);
    }
    return tab;
}
function SideNavBarLinkTabWithLabel({ icon, label, showLinkAsActive, href, accessibleName, onClick, onIsHoveredChanged }: SideNavBarLinkTabWithLabelProps) {
    const Link = useOctopusLinkComponent();
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    useOnIsHoveredChanged(linkRef, onIsHoveredChanged);
    return (<Link ref={linkRef} className={sideNavBarLinkTabWithLabelStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName} onClick={onClick} disableImplicitEventTracking={true}>
            {icon}
            {label}
        </Link>);
}
function SideNavBarLinkTabIconOnly({ icon, showLinkAsActive, href, accessibleName, onClick, onIsHoveredChanged }: SideNavBarLinkTabIconOnlyProps) {
    const Link = useOctopusLinkComponent();
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const isUrlActive = useIsUrlActive();
    const isActive = isUrlActive(href, showLinkAsActive ?? "if path partially matches");
    useOnIsHoveredChanged(linkRef, onIsHoveredChanged);
    return (<Link ref={linkRef} className={sideNavBarLinkTabIconOnlyStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName} onClick={onClick} disableImplicitEventTracking={true}>
            {icon}
        </Link>);
}
function isSideNavBarLinkTabWithLabel(item: SideNavBarLinkTabIconLinkProps): item is SideNavBarLinkTabWithLabelProps {
    return "label" in item && Boolean(item.label);
}
const sideNavBarLinkTabBaseStyles = cx(css(resetStyles.anchor, {
    "&[aria-current=page]": {
        color: themeTokens.color.text.selected,
        ":hover": {
            background: themeTokens.color.background.secondary.hovered,
        },
        "&::after": {
            content: "\"\"",
            position: "absolute",
            right: "0",
            height: "100%",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            width: "4px",
            background: colorScales.blue["400"],
        },
    },
}), sideNavBarTabStyles);
const sideNavBarLinkTabWithLabelStyles = cx(sideNavBarLinkTabBaseStyles, css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: space[2],
}));
const sideNavBarLinkTabIconOnlyStyles = sideNavBarLinkTabBaseStyles;
