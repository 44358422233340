import { useState } from "react";
interface AdvancedFilters {
    hasUserSelectedValues: boolean;
}
export function useAdvancedFiltersVisibility(advancedFilters: AdvancedFilters | undefined) {
    const [isAdvancedFiltersVisible, setIsAdvancedFiltersVisible] = useState<boolean | "not set">("not set");
    if (isAdvancedFiltersVisible === "not set" && advancedFilters !== undefined) {
        setIsAdvancedFiltersVisible(advancedFilters.hasUserSelectedValues);
    }
    return [isAdvancedFiltersVisible === "not set" ? false : isAdvancedFiltersVisible, setIsAdvancedFiltersVisible] as const;
}
