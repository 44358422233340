import type { CSSObject } from "@emotion/css";
const visuallyHiddenStyles: CSSObject = {
    clipPath: "inset(50%)",
    height: "1px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px",
};
export const accessibilityStyles = {
    hidden: visuallyHiddenStyles,
};
