import { css } from "@emotion/css";
import { text, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { EmptyStateDefaultIllustration } from "../../Illustrations";
import type { PrimaryPageAction, PageAction, PageActionsProps } from "../../PageActions";
import { PageActionComponent, toInternalPageAction, toInternalPrimaryPageAction } from "../../PageActions";
interface EmptyStateOnboardingProps {
    title: string;
    description?: React.ReactNode | string;
    image?: React.ReactElement;
    learnMore?: React.ReactElement;
    primaryAction?: PrimaryPageAction;
    actions?: PageAction[];
}
export function EmptyStateOnboarding({ title, description, image, primaryAction, actions, learnMore }: EmptyStateOnboardingProps) {
    return (<div className={pageContainerStyles.container}>
            <div className={pageContentStyles.container}>
                <div className={pageContentStyles.content}>
                    {title && (<>
                            <h2 className={pageContentStyles.header}>{title}</h2>
                            {description && <div className={pageContentStyles.description}>{description}</div>}

                            <div className={pageContentStyles.actions}>
                                <PageActions primaryAction={primaryAction} actions={actions}/>

                                {learnMore && learnMore}
                            </div>
                        </>)}
                </div>
                <div className={pageContentStyles.illustrationWrapperStyles}>{image ?? <EmptyStateDefaultIllustration />}</div>
            </div>
        </div>);
}
function PageActions({ primaryAction, actions }: PageActionsProps) {
    const hasPrimaryAction = primaryAction !== undefined;
    const actionButtons = actions?.map(toInternalPageAction);
    if ((!actions || actions.length === 0) && !hasPrimaryAction) {
        return null;
    }
    return (<>
            {hasPrimaryAction && <PageActionComponent key="primary" item={toInternalPrimaryPageAction(primaryAction)}/>}
            {actionButtons && actionButtons.map((item, id) => <PageActionComponent key={id} item={item}/>)}
        </>);
}
export default EmptyStateOnboarding;
const pageContainerStyles = {
    container: css({
        font: text.regular.default.medium,
        margin: space[32],
        display: "grid",
        height: `calc(100% - (${space[32]} * 2))`, // minus the top and bottom margin
        "@media (max-width: 1088px)": {
            alignContent: "start",
            textAlign: "center",
        },
    }),
};
const pageContentStyles = {
    container: css({
        display: "grid",
        gap: space[80],
        gridTemplateColumns: "2fr 2fr",
        alignItems: "center",
        justifyItems: "end",
        margin: "0 auto",
        "@media (max-width: 1488px)": {
            gap: space[64],
        },
        "@media (max-width: 1088px)": {
            justifyItems: "center",
            gridTemplateColumns: "1fr",
            gap: space[40],
        },
    }),
    illustrationWrapperStyles: css({
        width: "100%",
        "@media (max-width: 1088px)": {
            alignSelf: "end",
            order: "-1",
            maxWidth: "250px",
        },
    }),
    content: css({
        maxWidth: "536px",
        "@media (max-width: 1088px)": {
            alignSelf: "start",
        },
    }),
    header: css({
        font: text.heading.large,
        color: themeTokens.color.text.primary,
        margin: 0,
        marginBottom: space[16],
    }),
    description: css({
        font: text.regular.default.large,
        color: themeTokens.color.text.secondary,
        marginBottom: space[24],
    }),
    actions: css({
        display: "flex",
        gap: space[16],
        alignItems: "center",
        "@media (max-width: 1088px)": {
            justifyContent: "center",
            flexWrap: "wrap",
        },
    }),
    notification: css({
        marginBottom: space[32],
    }),
};
