import { css } from "@emotion/css";
import React from "react";
import { useIllustrationThemeName } from "../../Theme";
export function EmptyStateDefaultIllustration() {
    const currentTheme = useIllustrationThemeName();
    const blobFill = currentTheme === "light" ? "#F2F8FD" : "#2E475D";
    return (<svg className={illustrationStyles} viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={blobFill} fillRule="evenodd" clipRule="evenodd" d="M113.695 6.577c40.376-12.21 74.678 32.572 100.739 65.776 25.759 32.821 51.11 72.427 39.107 112.402-11.489 38.265-54.405 54.826-92.695 66.077-29.954 8.802-59.574-.193-88.068-12.961C42.788 224.434 1.234 211.65.02 178.774c-1.196-32.399 50.424-35.183 68.279-62.229 22.98-34.813 5.5-97.903 45.396-109.968Z"/>
            <path fill="#0D80D8" d="M99.622 161.727c5.575-3.614 12.131-9.821 9.645-17.13-1.357-3.981-3.238-7.389-3.489-11.692a26.549 26.549 0 0 1 1.597-10.716c5.005-13.516 19.929-19.918 33.326-15.845 12.45 3.763 21.024 18.358 15.859 30.979-2.975 7.297-4.309 13.034 2.326 18.667 1.802 1.549 6.18 3.855 6.157 6.597 0 3.58-7.012-.768-7.787-1.388.889 1.56 9.702 10.808 4.093 11.474-5.165.608-9.726-6.644-12.827-9.764-5.21-5.232-4.298 6.345-4.321 8.731 0 3.775-2.691 11.474-7.445 6.448-3.922-4.119-2.44-10.67-5.176-15.237-2.987-5.014-7.981 5.003-9.201 6.884-1.391 2.054-8.346 11.956-11.105 6.678-2.28-4.28 1.345-11.003 3.101-14.916-.638 1.4-5.199 3.442-6.533 4.165a17.669 17.669 0 0 1-9.44 2.192c-7-.517-1.63-4.257 1.22-6.104v-.023Z"/>
        </svg>);
}
const illustrationStyles = css({
    width: "100%",
    maxWidth: "500px",
    display: "block",
    "@media (max-width: 821px)": {
        maxWidth: "250px",
    },
});
