import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
interface ListItemContentProps {
    children: React.ReactNode;
}
export function ListItemContent({ children }: ListItemContentProps) {
    return <div className={listItemContentStyles}>{children}</div>;
}
const listItemContentStyles = css({
    padding: space["16"],
});
