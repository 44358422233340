import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
export interface SvgIconProps {
    children?: React.ReactNode;
    viewBox?: string;
    role?: "img" | "presentation";
    /** @deprecated */
    fill?: string;
    /** @deprecated */
    width?: string;
    /** @deprecated */
    height?: string;
    /** @deprecated */
    fontSize?: "default" | "inherit" | "large" | "medium" | "small";
    /** @deprecated */
    htmlColor?: string;
    /** @deprecated */
    className?: string;
    /** @deprecated */
    style?: React.CSSProperties;
    /** @deprecated */
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
}
export type LegacySvgIconProps = SvgIconProps;
export function LegacySvgIcon(props: LegacySvgIconProps) {
    return <MuiSvgIcon {...props}>{props.children}</MuiSvgIcon>;
}
