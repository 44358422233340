import { css } from "@emotion/css";
import { FilterIcon } from "@octopusdeploy/design-system-icons";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
import { ActionButton, ActionButtonType } from "../Button";
export interface FiltersProps {
    inputs: ReactNode[];
    showAdvancedFiltersToggle: boolean;
    isAdvancedFiltersVisible: boolean;
    onAdvancedFiltersVisibilityChanged: (isVisible: boolean) => void;
}
export function Filters({ inputs, showAdvancedFiltersToggle, isAdvancedFiltersVisible, onAdvancedFiltersVisibilityChanged }: FiltersProps) {
    return (<div className={filtersStyles.container}>
            {inputs.map((input, index) => (<React.Fragment key={index}>{input}</React.Fragment> // Filter ordering is unlikely to change, so using the index shouldn't be an issue
        ))}
            {showAdvancedFiltersToggle && (<ActionButton type={ActionButtonType.Ternary} label={`${isAdvancedFiltersVisible ? "Hide" : "Show"} advanced filters`} icon={<FilterIcon size={24}/>} onClick={() => {
                onAdvancedFiltersVisibilityChanged(!isAdvancedFiltersVisible);
            }}/>)}
        </div>);
}
export function FiltersSummary({ summary }: {
    summary: ReactNode;
}) {
    return <div className={filtersStyles.summary}>{summary}</div>;
}
const filtersStyles = {
    container: css({
        minWidth: 0,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: space[8],
        alignItems: "center",
    }),
    summary: css({}),
};
